<template>
    <div class="vue-tempalte">
        <form v-on:submit.prevent @submit="login()">
            <h2>Sign In</h2>

            <div class="form-group">
                <label>Email address</label>
                <input v-model.trim="email" type="email"
                  class="form-control" required/>
            </div>

            <div class="form-group">
                <label>Password</label>
                <input v-model="password" type="password"
                  class="form-control" required/>
            </div>
            <p v-if="showError"
              class="text-danger">Unable to log in with provided credentials.
              <br>
              If you're new user, please activate your account first.
            </p>
            <button
              type="submit" class="btn btn-dark btn-block">
                Sign In  <span v-if="isLoading" class="spinner-border"></span>
            </button>

            <p class="forgot-password text-right mt-2 mb-4">
                <router-link to="/signup/" exact>Create account ?  </router-link>
                <router-link to="/reset/" exact>Forgot password ?</router-link>
            </p>

            <!-- <div class="social-icons">
                <ul>
                    <li><a href="#"><i class="fa fa-google"></i></a></li>
                    <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                    <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                </ul>
            </div> -->

        </form>
    </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      isLoading: false,
      cuurentUser: {},
      email: '',
      password: '',
      showError: false,
    };
  },
  methods: {
    async login() {
      if (this.isLoading) { return; }
      this.isLoading = true;
      await this.$store.dispatch('user/retrieveToken', {
        email: this.email,
        password: this.password,
      }).then(() => {
        this.showError = false;
        this.$store.dispatch('user/retrieveUserData').then(() => {
          const redirectLink = Cookies.get('currentLink');
          if (redirectLink) {
            window.location.href = redirectLink;
          } else {
            this.$router.push('/');
          }
          Cookies.remove('currentLink');
          this.isLoading = false;
        });
      }).catch(() => {
        this.showError = true;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style scoped>
  form {
    max-width: 400px;
    margin: 10px auto;
    /* border: 2px solid grey; */
    padding: 12px 30px 5px 30px;
    border-radius: 30px;
    -webkit-box-shadow: 0px 0px 23px -8px rgba(10,10,10,1);
    -moz-box-shadow: 0px 0px 23px -8px rgba(10,10,10,1);
    box-shadow: 0px 0px 23px -8px rgba(10,10,10,1);
  }

  label {
    text-align: left !important;
    width: 100%;
  }

  .spinner-border {
    height: 1.5rem;
    width: 1.5rem;
  }
</style>
